import { Link } from '@remix-run/react';
import { Button } from '../ui/button';
import FeatureList from './FeatureList';
import { PageRoutes } from '~/constants/routes';
import CirclePlusIcon from '../icons/CirclePlusIcon';

export default function LargeScreenContent() {
  return (
    <div className="hidden w-1/2 rounded-xl border border-taupe bg-beige-light-20 px-4 py-4 screen-md:flex screen-md:flex-col screen-lg:py-8">
      <h2 className="text-2xl font-bold text-green-primary-text screen-lg:text-[46px]">
        <span className="flex flex-col items-start screen-lg:gap-4">
          <span className="text-green-primary-text">Ready to find the right </span>
          <span className="inline-flex w-fit rounded-lg bg-green-light-40 px-2 py-1 text-green-light screen-lg:py-2">
            parenting schedule?
          </span>
        </span>
      </h2>
      <h3 className="py-2 text-lg font-light screen-lg:py-4 screen-lg:text-[23px]">
        Just follow three easy steps to map out some simple schedules.
      </h3>
      <div className="w-full border-b border-dashed border-taupe pt-2" />
      <FeatureList />
      <div className="mt-4 flex items-center justify-start screen-lg:mt-6">
        <Button
          className="flex items-center justify-center rounded-full bg-green-dark px-6 py-4 font-sans text-base font-semibold normal-case text-neutral-white screen-lg:p-6 screen-lg:text-[23px]"
          asChild
          variant="primary"
        >
          <Link to={PageRoutes.TEMPLATES}>
            <span className="pr-2">Start here</span>
            <CirclePlusIcon />
          </Link>
        </Button>
      </div>
    </div>
  );
}
