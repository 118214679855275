import landingImageMedium from '~/assets/landing-small-4x.webp';
import landingImageSmall from '~/assets/landing-small-v2.webp';

export default function LandingPageImage() {
  return (
    <div className="w-full screen-md:w-1/2">
      <picture>
        <source srcSet={landingImageMedium} media="(min-width: 852px)" type="image/webp" />
        <source srcSet={landingImageSmall} media="(max-width: 851px)" type="image/webp" />
        <img src={landingImageSmall} width={360} height={251} alt="" className="h-full w-full" loading="eager" />
      </picture>
    </div>
  );
}
